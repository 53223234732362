import React from 'react';
import Table from './Table';
import Skeleton from './Skeleton';
import messages from './messages';
import Wrap from 'cms/components/Wrap';
import { useState } from 'react';
import { useEffect } from 'react';
import API from './api';
const OurSuppliersProducts = () => {
  const search = new URLSearchParams(window.location.search);
  const id = window.location.pathname.split('/')[2];
  const type = search.get('type');
  const [data, setData] = useState([]);
  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiCall = async () => {
    let response = null;
    if (type === 'povezani') response = await API.connectedSuppliers(id);
    else response = await API.unconnectedSuppliers(id);
    setData(response?.data?.data);
  };

  return (
    <Wrap
      pageTitle={messages.page_title}
      data={data}
      type="cms"
      isInline={false}
      // meta={meta}
      // params={meta?.per_page}
      // setSearch={handleSetSearch}
      // setParams={handleSetPagination}
      isVisibleSearch={false}
      isVisiblePagination={false}
      skeleton={<Skeleton className="web_margins" />}
      suppliers={true}
      helperVisible={false}
      tableContent={<Table key={String(data.length)} data={data} />}
    />
  );
};

export default OurSuppliersProducts;
